import { useState, useEffect } from 'react';
import { RiEdit2Line } from "react-icons/ri";
import SectionHeader from 'components/typography/section_header';
import { motion } from 'framer-motion';

const S4_Section = () => {
  const shares = [
    {
      name: 'Marketing/Future listing',
      percent: '10',
    },
    {
      name: 'Airdrop',
      percent: '5',
    },
    {
      name: 'Team',
      percent: '5',
    },
    {
      name: 'Liquidity',
      percent: '80',
    }
  ]

  const rollers = [
    {
      name: 'Tax',
      text: '0/0 %',
      rotate: 'rotate-[45deg]',
    },
    {
      name: 'LP',
      text: 'Burned at Launch',
      rotate: 'rotate-[-45deg]',
    },
    {
      name: <RiEdit2Line className='' />,
      text: 'Contract Ownership Is Renounced',
      rotate: 'rotate-[180deg]',
    },
    {
      name: 'Symbol',
      text: 'GFW',
      rotate: 'rotate-[95deg]',
    },
    {
      name: 'CA:',
      text: 'aLUt97sxZ78JohWw1G1jRh1xafn3soupC5bZECjmrDC',
      rotate: 'rotate-[135deg]',
    }
  ]

  // ::::::::::::::::::::::::: COPY CODE
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const textToCopy = 'aLUt97sxZ78JohWw1G1jRh1xafn3soupC5bZECjmrDC';
    navigator.clipboard.writeText(textToCopy)
      .then(() => setCopied(true))
      .catch((error) => console.error('Failed to copy:', error));
  };

  useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => {
        setCopied(false);
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [copied]);

  // :::::::::::::::::::::::::: FRAMER MOTION CODE
  const divVariant = {
    hidden: { y: 20, opacity: 0},
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5, ease: 'easeInOut' }
    }
  }

  const cardVariants = {
    hidden: {
      rotate: 60,
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      rotate: 0,
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1, ease: 'easeInOut'
      }
    }
  }

  const textContentVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1, ease: 'easeInOut'
      }
    }
  }

  return (
    <div id='tokenomics' className="relative z-[1] flex flex-col gap-[1rem] items-center justify-center w-full bg-black py-[4rem] overflow-x-hidden border-solid border-b-[1px] border-b-primary/50 ">
      <SectionHeader text='Tokenomics' />

      <div className='flex flex-col gap-[2.5rem] items-center w-full '>
        <motion.div 
          className='flex max-md:flex-col items-center justify-evenly gap-[1.5rem] '
          variants={divVariant}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.5, once: false }}
        >
          {shares.map((share, index) => (
            <div
              key={index}
              className='flex items-center justify-start gap-[0.15rem] '
            >
              <p className='text-[0.9375rem] md:text-[1.375rem] font-[600] bg-gradient-to-b from-primaryHover/90 to-primary text-transparent bg-clip-text '>{share.percent}%-</p>
              <p className='text-[0.9375rem] md:text-[1.375rem] font-[600] text-white/50 '>{share.name}</p>
            </div>
          ))}
        </motion.div>
        
        {/* :::::::::::::::::::::::: ROUND CARD SECTIONS */}
        <div className='relative flex md:gap-[1rem] items-center '>
          <div className='flex flex-col gap-[1rem] '>
            {rollers.slice(0,3).map((roller, index) => (
            <motion.div
              key={index}
              custom={index}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.5, once: false }}
              className={`relative flex flex-col items-center justify-center text-center gap-[1rem] p-[2rem] h-[9.25rem] w-[9.25rem] md:h-[16rem] md:w-[16rem] `}
            >
              <div 
                className='flex flex-col items-center justify-center text-center gap-[1rem]'
                custom={index}
                variants={textContentVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.5, once: false }}
              >
                <h2 className='text-white text-[1.0625rem] md:text-[2rem] font-[600] '>{roller.name}</h2>
                <p className={`text-white/80 text-[0.9375rem] md:text-[1.5rem]`}>{roller.text}</p>
              </div>
              {/* ::::::::::: BACKGROUND CREST MOON */}
              <img
                src='/assets/svgs/crested_moon.svg'
                alt='crest moon border line'
                className={`absolute top-0 right-0 z-[-1] w-full h-full object-contain ${roller.rotate}`}
              />
            </motion.div>
          ))}
          </div>

          <div className='flex flex-col items-center gap-[1rem] '>
          {/* ::::::::::: INDEX 3 */}
          {rollers[3] && (
            <motion.div
              custom={3}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.5, once: false }}
              className='relative flex flex-col items-center justify-center text-center gap] w-[24.125rem-[1rem] h-[10rem] md:h-[20.125rem] w-[10rem] md:w-[20.125rem] p-[3rem]'
            >
              <h2 className='text-white text-[1.0625rem] md:text-[2rem] font-[600]'>{rollers[3].name}</h2>
              <p className='text-white/80 text-[0.9375rem] md:text-[2rem] font-[600]'>{rollers[3].text}</p>

              {/* ::::::::::: BACKGROUND CREST MOON */}
              <img
                src='/assets/svgs/crested_moon.svg'
                alt='crest moon border line'
                className={`absolute top-0 right-0 z-[-1] w-full h-full object-contain ${rollers[3].rotate}`}
              />
            </motion.div>
          )}

          {/* ::::::::::: INDEX 3 */}
          {rollers[4] && (
            <motion.div
              custom={4}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.5, once: false }}
              className='relative flex flex-col items-center justify-center text-center gap-[1rem] h-[10.375rem] sm:h-[12.375rem] md:h-[20.125rem] w-[10.375rem] sm:w-[12.375rem] md:w-[20.125rem] p-[3rem]'
            >
              <h2 className='text-white text-[1.0625rem] md:text-[2rem] font-[600]'>{rollers[4].name}</h2>
              <p className='text-white/80 text-[0.5rem] md:text-[0.875rem] font-[600] w-full break-all'>{rollers[4].text}</p>
              <button
                onClick={handleCopy}
                className='flex items-center justify-center w-[4.5625rem] h-[1.75rem] rounded-[4px] text-primary bg-[#222222] hover:bg-[#111111] hover:text-white ease-250 '
              >Copy</button>

              {/* ::::::::::: BACKGROUND CREST MOON */}
              <img
                src='/assets/svgs/crested_moon.svg'
                alt='crest moon border line'
                className={`absolute top-0 right-0 z-[-1] w-full h-full object-contain ${rollers[4].rotate}`}
              />
            </motion.div>
          )}
          </div>
        </div>

        {/* :::::::::::::::::::::::::::: TEXT SECTION WITH CURVED BORDER */}
        <div className='flex flex-col items-center text-center gap-[1rem] w-full max-w-[87.5rem] px-[1rem] md:px-[4rem] pt-[3rem] '>
          <p className='w-full p-[2rem] text-[0.875rem] font-[600] border-solid border-x-[1px] border-t-[1px] border-b-0 border-primary rounded-[32px] '>If you think about selling GFW right after the first small profit, then you don't understand it's potential.</p>
          <small className='text-[0.625rem] max-w-[50rem] '>I know it's hard to hold when you see a little profit, but hold on and you won't regret it. GFW is here for you when you can't send your kids to college, when you can't pay the mortgage on your house, when your health demands financial investment; GFW will be there to help you, just believe in it. Don't sell it for small short-term profits; hold onto it until a critical situation arises. That's his purpose." </small>
          <p className='text-[1rem] font-[600] '>The first goal is a $10 million market cap</p>
        </div>
      </div>
    </div>
  )
}

export default S4_Section;
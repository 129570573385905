import { motion } from 'framer-motion';

const HeroSection = () => {  
  const divVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.25,
        ease: 'easeInOut',
      }
    }
  }

  return (
    <div id='home' className="relative z-[1] flex w-full h-max min-h-[50rem] bg-primaryHover overflow-x-hidden pb-[5rem] pt-[4rem] md:pt-[14rem] ">
      <div className='flex max-md:flex-col-reverse justify-between gap-y-[1rem] items-center py-[2rem] px-[2rem] w-full max-w-[66.0625rem] mx-auto '>  
        <div className='relative flex flex-col md:gap-[0.5rem] w-full max-md:text-center max-md:items-center md:max-w-[28.75rem] '>
          {/* <h2 className='text-[3.625rem] font-[600] '>Hop on</h2> */}
          <h2 className='text-[3.5rem] md:text-[5.25rem] font-[800] text-primary'>Giraffe Warrior</h2>
          <p className='text-[0.8125rem] '>Giraffe Warrior is a community-driven token on a mission to make a long-lasting impact.We're more than just a meme coin - we're building a strong foundation for the future.</p>

          {/* ::::::::::::::::::::::::::: CTA */}
          <motion.div 
            className='flex gap-[1rem] items-center max-md:flex-col pt-[1rem] '
            variants={divVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: false }}
          >
            <a 
              href='https://dexscreener.com/solana/4eu6pukiecb9pxfr13ndbx9vuphtcmex4ncp6kw7m66t'
              target='_blank'
              rel='noreferrer'
              className='flex items-center max-md:justify-evenly gap-[0.875rem] max-md:w-[14rem] h-[2.5rem] md:h-[3.375rem] bg-white rounded-[8px] px-[1rem] md:px-[1.25rem] hover:opacity-[95%] transform ease-250 hover:scale-[1.05]'
            >
              <p className='text-black text-[0.9375rem] md:text-[1.0625rem] font-[600] '>Buy on Dexscreener</p>
              {/* <RiShoppingCart2Line className='text-[#000000] text-[1.5rem] ' /> */}
              <img 
                src="/assets/svgs/dexscreener_black.svg" 
                alt="Descreener" 
                className='h-[1.5rem] w-[1.5rem] min-w-[1.5rem] object-contain'
              />
            </a>
            <a 
              href='https://dexscreener.com/solana/4eu6pukiecb9pxfr13ndbx9vuphtcmex4ncp6kw7m66t'
              target='_blank'
              rel='noreferrer'
              className='flex items-center justify-center max-md:justify-evenly gap-[1rem] max-md:w-[14rem] h-[2.5rem] md:h-[3.375rem] min-w-[11rem] bg-transparent rounded-[8px] px-[1rem] md:px-[1.25rem] border-solid border-[1px] border-white hover:opacity-[90%] transform ease-250 hover:scale-[1.05]'
            >
              <p className='text-white text-[0.9375rem] md:text-[1.0625rem] font-[600] '>See the Chart</p>
            </a>
          </motion.div>

          {/* :::::::::::::::::: JAIL EXHIBIT */}
          {/* <img
            src='/assets/images/section_girrafe.jpg'
            alt='JAIL EXHIBIT'
            className='max-md:hidden absolute top-0 right-[2rem] z-[5] w-[5rem] object-contain shake'
          /> */}
        </div>
        
        <div className='flex items-center justify-center md:justify-end w-[18.75rem] h-[18.75rem] min-w-[18.75rem] '>
          <img
            src='/assets/images/section_girrafe.jpg'
            alt='LOGO'
            className='w-[14.75rem] h-[14.75rem] min-w-[14.75rem] object-cover rounded-[100rem] hover:scale-[1.1] shadow-sm shadow-[#d68e07] ease-250'
          />
        </div>
      </div>

      {/* ::::::::::::::::::::::: OVERLAY */}
      <div className='absolute z-[-1] top-0 left-0 w-full h-full bg-gradient-to-b from-[#000000] via-[#111111] to-[#000000] opacity-[90%] '>
        <img
          src='/assets/images/hero_girrafe.jpg'
          alt='background'
          className='w-full h-full object-cover object-top opacity-[20%] '
        />
      </div>
    </div>
  )
}

export default HeroSection;
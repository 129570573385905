/* eslint-disable react/jsx-pascal-case */
import MainLayout from 'components/layout';
import HeroSection from './components/s1_hero';
import S2_Section from './components/s2_section';
import S3_Section from './components/s3_section';
import S4_Section from './components/s4_section';
import S5_Section from './components/s5_section';
import S6_Section from './components/s6_section';

const HomePage = () => {
  return (
    <MainLayout>
      <HeroSection />
      <S2_Section />
      <S3_Section />
      <div className='flex max-md:flex-col justify-evenly items-center gap-y-[4rem] gap-[1rem] px-[1rem] py-[4rem] '>
        <iframe id="dextswap-aggregator-widget" title="DEXTswap Aggregator" className='w-full max-w-[31.25rem] md:max-w-[31.25rem] h-[25rem]' src="https://www.dextools.io/widget-aggregator/en/swap/solana/aLUt97sxZ78JohWw1G1jRh1xafn3soupC5bZECjmrDC"></iframe>

        <iframe id="dextools-widget" title="DEXTools Trading Chart" className='w-full max-w-[31.25rem] h-[25rem]' src="https://www.dextools.io/widget-chart/en/solana/pe-light/4eU6pukiecb9pXFR13NDBX9vUPHtCmEx4ncp6kW7m66T?theme=light&chartType=2&chartResolution=30&drawingToolbars=false"></iframe>
      </div>
      <S4_Section />
      <S5_Section />
      <S6_Section />
    </MainLayout>
  )
}

export default HomePage
import SectionHeader from 'components/typography/section_header';
import React, { useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import { motion } from 'framer-motion';

const S6_Section = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'How to Buy GFWWARRIOR?',
      open: false,
      answers: [
        'Download Phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to phantom.app.',
        'Have SOL in your wallet to switch to GFWWARRIOR. If you don’t have any SOL, you can buy SOL from an exchange or cross chain swap and send it to your wallet.',
        'Connect to Raydium or Jupiter. Go raydium.io or jup.ag in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the GFW token address into Raydium or Jupiter and confirm the swap. When Phantom prompts you for a wallet signature, sign.'
      ]
    },
    {
      question: "Will GFWWARRIOR list on more CEX's?",
      open: false,
      answers: ['Yes. GFWWARRIOR will be listed on most major worldwide exchanges.']
    },
    {
      question: 'You have more questions?',
      open: false,
      answers: ['Join our community on Telegram. Join Us']
    }
  ]);

  const toggleFAQ = (index) => {
    setFaqs(prevFaqs => 
      prevFaqs.map((faq, i) => ({
        ...faq,
        open: i === index ? !faq.open : faq.open
      }))
    );
  };

  const faqVariants = {
    hidden: { x: -50, opacity: 0 },
    visible: { 
      x: 0, 
      opacity: 1,
      transition: { duration: 0.6, delay: 0.1, ease: 'easeInOut' }
    }
  };

  return (
    <div id='faq' className='relative z-[1] flex flex-col gap-[3rem] items-center justify-center w-full h-max bg-black pt-[2rem] pb-[6rem] overflow-x-hidden px-[1rem] md:px-[2rem] '>
      <SectionHeader text='FAQ' />

      <div className='flex flex-col gap-[1rem] items-center mx-auto max-w-[72.5rem]'>
        {faqs.map((faq, index) => (
          <motion.div 
            key={index}
            className='relative flex flex-col items-center w-full'
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: false }}
            variants={faqVariants}
          >
            <button 
              onClick={() => toggleFAQ(index)}
              className='flex items-center justify-between px-[2rem] w-full bg-[#ffffff]/10 py-[1.5rem] rounded-[16px] text-left hover:scale-[1.02] ease-250'
            >
              <p className='text-[1rem] md:text-[1.375rem] font-[600]'>{faq.question}</p>
              <RiArrowDownSLine className={`text-white text-[1.5rem] transform ${faq.open ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`} />
            </button>

            <div 
              className={`bg-[#0b0b0b] text-left w-full rounded-[16px] overflow-hidden transition-all duration-300 ease-in-out ${faq.open ? 'max-h-[35.25rem] opacity-100' : 'max-h-0 opacity-0'}`}
              style={{ transitionProperty: 'max-height, opacity' }}
            >
              {faq.answers.map((answer, i) => (
                <p key={i} className='text-[0.875rem] md:text-[1.15rem] font-[600] px-[2rem] py-[1rem]'>{answer}</p>
              ))}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default S6_Section;

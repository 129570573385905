import { RiTwitterLine } from "react-icons/ri";
import { PiTelegramLogoDuotone } from "react-icons/pi";
import { motion } from 'framer-motion';

const Footer = () => {
  const socials = [
    {
      name: 'Twitter',
      url: 'https://x.com/GIRAFFE_WARRIOR?t=yzc3E0c-DsyWLb1QmIfLNQ&s=09',
      icon: <RiTwitterLine className='text-[1.875rem] md:text-[2rem] text-white' />
    },
    {
      name: 'Telegram',
      url: 'http://t.me/giraffewarriorcommunity',
      icon: <PiTelegramLogoDuotone className='text-[1.875rem] md:text-[2rem] text-white' />
    },
    {
      name: 'Dexscreener',
      url: 'https://dexscreener.com/solana/4eu6pukiecb9pxfr13ndbx9vuphtcmex4ncp6kw7m66t',
      icon: '/assets/svgs/dexscreener_white.svg'
    },
    {
      name: 'Raydium',
      url: 'https://raydium.io/swap/?inputMint=sol&outputMint=aLUt97sxZ78JohWw1G1jRh1xafn3soupC5bZECjmrDC',
      icon: '/assets/images/raydium.png'
    },
    {
      name: 'Dextools',
      url: 'https://www.dextools.io/app/en/solana/pair-explorer/4eU6pukiecb9pXFR13NDBX9vUPHtCmEx4ncp6kW7m66T?t=1720507972718',
      icon: '/assets/svgs/dextools.svg'
    }
    // https://www.dextools.io/app/en/solana/pair-explorer/4eU6pukiecb9pXFR13NDBX9vUPHtCmEx4ncp6kW7m66T?t=1720507972718
  ]

  const iconLinkVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
      y: 20,
    },
    visible: (index) => ({
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 0.25,
        ease: 'easeInOut',
        delay: index * 0.08
      }
    })
  }

  return (
    <div className="relative flex items-center justify-center gap-[1rem] md:gap-[3rem] w-full h-max rounded-t-[5rem] pl-[1rem] md:pl-[2rem] pt-[1rem] border-solid border-t-[1px] border-0 border-primary/50 ">
      {/* <div className='absolute top-0 left-0 z-[-1] w-full h-[4rem] border-solid border-t-[1px] border-x-[1px] border-b-0 border-primary/50 rounded-t-[5rem] '></div> */}
      <div className='flex flex-col gap-[0.5rem] items-center pb-[2rem] '>
      {socials.map((social, index) => (
        <motion.a 
          key={index}
          href={social.url}
          className='mt-[2rem]'
          custom={index}
          variants={iconLinkVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.5, once: false }}
          whileHover={{ scale: 1.1, transition: { duration: 0.25, ease: 'easeInOut' } }}
        >
          {[2,3,4].includes(index)?
          <img src={`${social.icon}`} alt={social.name} className='h-[1.875rem] md:h-[2rem] w-[1.875rem] md:w-[2rem] min-w-[1.875rem] md:min-w-[2rem] object-contain' /> :
          <>{social.icon}</>
          }
        </motion.a>
      ))}
      </div>

      <div className='flex flex-1 flex-col justify-center gap-[1rem]'>
        <h2 className='text-[2rem] md:text-[3.5rem] font-[800] text-primary'>GIRAFFE WARRIOR</h2>
        <p className='max-w-full text-[0.875rem] md:text-[1.25rem] text-secondary pr-[1rem] '>GFW WAS CREATED FOR FUN AND BUZZ. INVEST AT YOUR FINANCIAL CAPABILITY. DO NOT TAKE FINANCIAL RISK IF YOU ARE AFRAID OF LOSS. WE DO NOT CONTROL THE PRICES, IT ALL DEPENDS ON THE COMMUNITY AND MARKET DYNAMICS.</p>

        <hr className='w-full h-[1px] bg-primary/50 my-[1rem] ' />

        <p className='text-[0.75rem] md:pl-[1rem] mt-[-1rem]'>© {new Date().getFullYear()} Giraffe Warrior • All Rights Reserved</p>
      </div>
    </div>
  )
}

export default Footer

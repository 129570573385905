import SectionHeader from 'components/typography/section_header';
import React from 'react';
import { motion } from 'framer-motion';

const S2_Section = () => {
  const items = [
    {
      image: '/assets/images/section_girrafe.jpg',
      title: 'About Giraffe Warrior:',
      text: `Giraffe Warrior is a community-driven token on a mission to make a long-lasting impact.exclamation We're more than just a meme coin - we're Warriors. we don't promise any financial return on investment,it's pure fun and cruise`     
    },
    {
      image: '/assets/images/hero_girrafe.jpg',
      title: 'The Mission',
      text: `Our roadmap is our commitment to building a vibrant and dedicated community centered around memes and cryptocurrency. We believe in ourselves as community founded on fun,creativity, and the boundless potential of meme culture.`
    }
  ]

  const imageVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <div id='about' className="relative flex flex-col items-center justify-center gap-[3rem] w-full h-max bg-black py-[2rem] pb-[8rem] border-solid border-t-[1px] border-b-[1px] border-x-0 border-primary/50 ">
      <SectionHeader text='About Giraffe Warrior' />

      <div className='flex flex-col gap-[4rem] '>
        {items.map((item, index) => (
          <motion.div
            key={index}
            className='flex max-md:flex-col items-center gap-[1.5rem] h-max w-full max-w-[73.9375rem] px-[2rem]'
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: false }}
          >
            <motion.img
              src={item.image}
              alt='background'
              className='w-[11.25rem] h-[11.25rem] min-w-[11.25rem] object-cover rounded-[100rem] outline outline-[2px] outline-primary'
              variants={imageVariants}
            />

            <motion.div
              className='relative flex flex-col gap-[0.5rem] pl-[1.5rem] overflow-y-hidden'
              variants={textVariants}
            >
              <div className='absolute top-[0] left-0 bg-primary/50 w-[1px] h-full'></div>
              <h2 className='max-md:mx-auto text-primary text-[1.4375rem] font-[600]'>
                {item.title}
              </h2>
              <p>{item.text}</p>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default S2_Section;
